export const CREATE_BLOG_REQUEST = "CREATE_BLOG_REQUEST";
export const CREATE_BLOG_SUCCESS = "CREATE_BLOG_SUCCESS";
export const CREATE_BLOG_FAIL = "CREATE_BLOG_FAIL";
export const GET_ALL_BLOG_REQUEST = "GET_ALL_BLOG_REQUEST";
export const GET_ALL_BLOG_SUCCESS = "GET_ALL_BLOG_SUCCESS";
export const GET_ALL_BLOG_FAIL = "GET_ALL_BLOG_FAIL";
export const GET_BLOG_REQUEST = "GET_BLOG_REQUEST";
export const GET_BLOG_SUCCESS = "GET_BLOG_SUCCESS";
export const GET_BLOG_FAIL = "GET_BLOG_FAIL";
export const UPLOAD_BLOG_IMAGE_REQUEST = "UPLOAD_BLOG_IMAGE_REQUEST";
export const UPLOAD_BLOG_IMAGE_SUCCESS = "UPLOAD_BLOG_IMAGE_SUCCESS";
export const UPLOAD_BLOG_IMAGE_FAIL = "GET_BLOG_IMAGE_FAIL";
