export const UPLOAD_BANNER_REQUEST = "UPLOAD_BANNER_REQUEST";
export const UPLOAD_BANNER_SUCCESS = "UPLOAD_BANNER_SUCCESS";
export const UPLOAD_BANNER_FAIL = "UPLOAD_BANNER_FAIL";
export const GET_BANNERS_REQUEST = "GET_BANNERS_REQUEST";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAIL = "GET_BANNERS_FAIL";
export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";

export const UPDATE_CONTENT_REQUEST = "UPDATE_CONTENT_REQUEST";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const UPDATE_CONTENT_FAIL = "UPDATE_CONTENT_FAIL";
export const GET_CONTENTS_REQUEST = "GET_CONTENTS_REQUEST";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
export const GET_CONTENTS_FAIL = "GET_CONTENTS_FAIL";
