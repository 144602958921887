import React, { useEffect, useState } from "react";
// Material Ui
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Rating,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";

// Components Import
import ProductTabs from "../components/tabs";
import StyledButton from "../components/styledButton";
// React Redux
import { useSelector, useDispatch } from "react-redux";
import { getProductDetails } from "../redux/actions/productsActions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addToCart } from "../redux/reducers/cartReducer";
import ProductSlider from "../components/productSlider";
import toast from "react-hot-toast";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RelatedSlider from "../components/ProductsSlider";
import ReviewSection from "../components/ReviewSection";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import Metadata from "../components/metadata";

const Product = ({ handleAddToFavorites }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const product = useSelector((state) => state.product);
  const data = product?.data?.product;
  // Add to cart
  const [size, setSize] = useState(0);
  const [flavor, setFlavor] = useState(0);
  const [sizeCart, setSizeCart] = useState("");
  const [flavorCart, setFlavorCart] = useState("");
  const [quantity, setQuantity] = useState(1);
  const handleAddToCart = () => {
    if (!sizeCart) {
      toast.error("Please select your desired size and flavor!");
      return;
    }
    if (quantity > data?.variants[size]?.quantity) {
      toast.error(`Only ${data?.variants[size]?.quantity} in stock!`);
      return;
    }
    dispatch(
      addToCart({ product: data, size: sizeCart, flavor: flavorCart, quantity })
    );
    toast.success("Added to Cart!");
    window.setTimeout(function () {
      navigate("/cart");
    }, 2000);
  };
  useEffect(() => {
    dispatch(getProductDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    setSizeCart(data?.variants[size]?.size);
    setFlavorCart(data?.variants[size]?.flavors[flavor]);
  }, [data, size]);
  return (
    <>
      <Metadata
        title={`${data?.name} - Xtrack.pk`}
        description={data?.description}
        image={data?.variants[0]?.images[0]?.url}
      />
      {/* <Box
        sx={{
          height: "13vh",
          width: "100%",
          // display: { xs: "none", sm: "none", md: "block" },
        }}
      ></Box> */}
      {product.loading ? (
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Box sx={{ width: "100%", minHeight: "80vh" }}>
            <Grid container>
              {/* Slider */}
              <Grid
                item
                xs={12}
                lg={6}
                sx={{ height: { xs: "55vh", sm: "70vh" } }}
              >
                <ProductSlider images={data?.variants[size]?.images} />
              </Grid>
              {/* Content */}
              <Grid item xs={12} lg={6} sx={{ padding: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="subtitle1"
                    sx={{
                      textTransform: "uppercase",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    {data?.category}
                  </Typography>
                  {data?.variants[size]?.quantity <= 0 && (
                    <Typography
                      component="h1"
                      variant="subtitle1"
                      sx={{
                        textTransform: "capitalize",
                        fontFamily: "Poppins, sans-serif",
                        color: "white",
                        background: "#e63146",
                        padding: "4px 8px",
                      }}
                    >
                      Sold Out
                    </Typography>
                  )}
                </Box>
                <Typography
                  component="h1"
                  sx={{
                    fontSize: { xs: "4vh", sm: "6vh" },
                    fontWeight: "700",
                    marginTop: "0px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {data?.name}
                </Typography>
                {data?.reviews?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "center",
                    }}
                  >
                    <Rating
                      name="read-only"
                      value={data?.ratings}
                      readOnly
                      size="medium"
                      sx={{ color: "#e63146" }}
                      emptyIcon={
                        <StarBorderIcon sx={{ fontSize: "inherit" }} />
                      }
                    />
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontFamily: "Poppins, sans-serif",
                        paddingLeft: "10px",
                      }}
                    >
                      {data?.ratings} ({data?.reviews?.length} Reviews)
                    </Typography>
                  </Box>
                )}
                {data?.brand && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "500",
                      marginTop: "0px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    By:{" "}
                    <Link
                      to="/shop"
                      state={{
                        brand: data?.brand?.toLowerCase(),
                      }}
                    >
                      {data?.brand}
                    </Link>
                  </Typography>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0 12px",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{
                      textDecoration: "line-through",
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                    }}
                  >
                    Rs.{data?.variants[size]?.discount}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h4"
                    sx={{
                      fontWeight: 700,
                      fontFamily: "Poppins, sans-serif",
                      color: "#e63146",
                    }}
                  >
                    Rs.{data?.variants[size]?.price}
                  </Typography>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      fontFamily: "Poppins, sans-serif",
                      background: "#e63146",
                      color: "white",
                      borderRadius: "5px",
                      padding: "0 8px",
                    }}
                  >
                    Save Rs.
                    {data?.variants[size]?.discount -
                      data?.variants[size]?.price}
                  </Typography>
                </Box>
                {data?.variants[size]?.expiry && (
                  <Typography
                    component="h1"
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      // display: "flex",
                      // alignItems: "center",
                      marginY: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ color: "#e63146", paddingX: 2 }}>
                      Expiry:
                    </span>{" "}
                    {data?.variants[size]?.expiry}
                  </Typography>
                )}
                <Divider />
                <Typography
                  component="h1"
                  variant="subtitle1"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    display: "flex",
                    alignItems: "center",
                    marginY: "10px",
                  }}
                >
                  <LocalShippingOutlinedIcon
                    sx={{ fontSize: "36px", paddingRight: "6px" }}
                  />{" "}
                  {data?.shipping === 0
                    ? "Free Delivery All Over Pakistan"
                    : `Delivery for only Rs.${data?.shipping} All Over Pakistan`}
                </Typography>
                <Divider />
                {data?.variants?.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        marginTop: "5px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                      variant="body1"
                    >
                      Select your variants:
                    </Typography>
                    <Grid container>
                      {data?.variants?.map((variant, i) => (
                        <Grid
                          item
                          md={3}
                          xs={6}
                          key={i}
                          sx={{ padding: "4px 8px" }}
                        >
                          <Button
                            sx={{
                              width: "100%",
                              border: `1.5px solid ${
                                size === i ? "#e63146" : "black"
                              }`,
                              borderRadius: "7px",
                              textAlign: "center",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              color: size === i ? "white" : "black",
                              fontSize: "18px",
                              textTransform: "capitalize",
                              background:
                                size === i ? "#e63146" : "transparent",
                            }}
                            onClick={() => {
                              setSize(i);
                              setSizeCart(variant?.size);
                            }}
                          >
                            {variant?.size}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                {data?.variants[size].flavors?.length > 0 && (
                  <Grid container>
                    <Grid item xs={12} sx={{ padding: "4px 8px" }}>
                      <Typography
                        sx={{
                          marginTop: "5px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                        variant="body1"
                      >
                        Select your flavors:
                      </Typography>
                    </Grid>
                    {data?.variants[size].flavors?.map((variant, i) => (
                      <Grid
                        item
                        md={3}
                        xs={6}
                        key={i}
                        sx={{ padding: "4px 8px" }}
                      >
                        <Button
                          sx={{
                            width: "100%",
                            border: `1.5px solid ${
                              flavor === i ? "#e63146" : "black"
                            }`,
                            borderRadius: "7px",
                            textAlign: "center",
                            minHeight: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            color: flavor === i ? "white" : "black",
                            fontSize: "15px",
                            textTransform: "capitalize",
                            background:
                              flavor === i ? "#e63146" : "transparent",
                          }}
                          onClick={() => {
                            setFlavor(i);
                            setFlavorCart(variant);
                          }}
                        >
                          {variant}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                )}

                <Box
                  sx={{
                    width: "100%",
                    marginY: "8px",
                    paddingLeft: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    aria-label="remove"
                    onClick={() =>
                      setQuantity(quantity === 1 ? 1 : quantity - 1)
                    }
                    size="large"
                    sx={{ color: "#e63146", paddingRight: "15px" }}
                  >
                    <RemoveCircleOutlineIcon fontSize="inherit" />
                  </IconButton>
                  <Typography sx={{ fontFamily: "Poppins, sans-serif" }}>
                    {quantity}
                  </Typography>
                  <IconButton
                    aria-label="add"
                    onClick={() => setQuantity(quantity + 1)}
                    size="large"
                    sx={{ color: "#e63146", paddingLeft: "15px" }}
                  >
                    <AddCircleOutlineIcon fontSize="inherit" />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "row" },
                    height: "fit-content",
                    margin: "15px 0",
                    gap: "12px",
                  }}
                >
                  <StyledButton
                    title={"Add to Cart"}
                    onClick={handleAddToCart}
                  />
                  <StyledButton
                    title={"Buy it Now"}
                    onClick={handleAddToCart}
                    mode="dark"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* Tabs */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              margin: "20px 0",
            }}
          >
            <ProductTabs data={data} />
          </Box>
          {/* Review */}
          <Box
            sx={{ display: "flex", justifyContent: "center", marginY: "30px" }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: "80%",
                },
                minHeight: "40vh",
              }}
            >
              <ReviewSection reviews={data} id={data?._id} />
            </Box>
          </Box>
          {/* Related Products */}
          {data?.related?.length > 0 && (
            <Box
              sx={{
                padding: "20px 10px",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Related Products
              </Typography>
              <RelatedSlider
                products={data?.related}
                favorite={handleAddToFavorites}
                cart={false}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Product;
